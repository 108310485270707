import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const allBlogs = () => {
  return (
    <StaticQuery
      query={graphql`
        query AllBlogsQuery {
          allMdx(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
              slug
              frontmatter {
                title
                date(formatString: "Do of MMMM")
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <h2>Other Posts</h2>
          <ul>
            {data.allMdx.nodes.map((item) => {
              return (
                <li key={item.slug}>
                  {item.frontmatter.title} | <small>Published on {item.frontmatter.date} </small>
                  <Link to={'/'+item.slug}>Visit</Link>
                </li>
              )
            })}
          </ul>
          <StaticImage src="../images/wood.jpg" alt="wood" />
        </div>
      )}
    />
  )
}

export default allBlogs
