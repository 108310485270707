import React from 'react'
import AllBlogs from './AllBlogs'

const blog = ({pageContext}) => {
  const {title, date, description} = pageContext.frontmatter;
  return (
    <div>
      {title} - {date} [{description}]

      <AllBlogs />
    </div>
  )
}

export default blog

